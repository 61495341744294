import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient, {
  GiftCategory,
} from "../../../api/giftCategories";

const useUpdateCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["categories"],
    mutationFn: (category: GiftCategory) =>
      giftCategoriesClient.updateCategory(category._id, category),
    onMutate: async (category: GiftCategory) => {
      queryClient.setQueryData(["categories"], (oldData: GiftCategory[]) => {
        return oldData.map((oldCategory) =>
          oldCategory._id === category._id ? category : oldCategory,
        );
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["categories"],
      }),
  });
};
export default useUpdateCategoryMutation;
