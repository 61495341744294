import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import KlydoListItem from "./KlydoListItem";
import { GiftCategory } from "../api/giftCategories";
import { Klydo } from "../Types";
import firebaseService from "../firebase_service/firebaseService";
import useUpdateCategoryMutation from "./GiftCategories/hooks/useUpdateCategoryMutation";
import uploadFile from "../utils/uploadFiles";
import ButtonLoader from "../components/Widgets/ButtonLoader";

const GiftCategoryModal = (props: {
  row?: GiftCategory;
  setShowModal: (b: boolean) => void;
}) => {
  const [giftCategoryImageUrl, setGiftCategoryImageUrl] = useState<string>(
    props?.row?.categoryImageUrl || "",
  );
  const [inputText, setInputText] = useState<string>("");
  const [name, setName] = useState<string>(props.row?.name || "");
  const [error, setError] = useState<{
    server?: string;
    klydo?: string;
    pic?: string;
  }>({});
  const [draftKlydos, setDraftKlydos] = useState<Klydo[] | undefined>(
    props.row ? undefined : [],
  );
  const [suggestions, setSuggestions] = useState<Array<Klydo>>();
  const [removedKlydos, setRemovedKlydos] = useState<Klydo[]>([]);
  const [klydoValid, setKlydoValid] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [selectedKlydo, setSelectedKlydo] = useState<Klydo>();
  const { mutate: updateCategoryMutation } = useUpdateCategoryMutation();

  useEffect(() => {
    if (props.row && !draftKlydos) {
      if (props.row.klydoIds && props.row.klydoIds.length > 0)
        firebaseService
          .getList<Klydo>("klydos", "id", props.row.klydoIds)
          .then(setDraftKlydos);
      else setDraftKlydos([]);
    }
  }, [props.row]);

  useEffect(() => {
    let klydo: Klydo | undefined = undefined;
    let validated = false;
    const idf = inputText.split("-").length === 3;
    firebaseService
      .findItem<Klydo>("klydos", idf ? "idf" : "id", inputText)
      .then((v) => {
        klydo = v;
        validated = klydo !== undefined;
        setKlydoValid(validated);

        setError((newError) =>
          validated || inputText.length === 0
            ? { ...newError, klydo: "" }
            : { ...newError, klydo: "There is no such klydo" },
        );
        if (validated) setSelectedKlydo(klydo!);
      });
  }, [inputText]);

  const handleRemoveKlydo = (klydo: Klydo) => {
    removedKlydos.push(klydo);
    setRemovedKlydos((prev) => prev.slice());
    if (draftKlydos) {
      draftKlydos.splice(draftKlydos.indexOf(klydo), 1);
    }
    setDraftKlydos((prev) => prev?.slice());
  };

  const saveGiftCategory = async () => {
    updateCategoryMutation({
      _id: props.row!._id!,
      name: name,
      categoryImageUrl: giftCategoryImageUrl,
      klydoIds: draftKlydos?.map((klydo) => klydo.id) || [],
    });
  };

  return (
    <>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          {"Gift Category"}
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row
            style={{
              paddingBottom: 8,
            }}
          >
            <Col>
              <Form noValidate onSubmit={saveGiftCategory} id="newPackForm">
                <Row>
                  <Col sm="6" style={{ width: "100%" }}>
                    <Form.Group
                      controlId="formAddName"
                      style={{ display: "flex" }}
                    >
                      <Form.Label style={{ padding: 5, width: "20%" }}>
                        <b>Gift Category's Name:</b>
                      </Form.Label>
                      <Col>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="name"
                          placeholder="Enter name..."
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Gift Category logo</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = (e.target as HTMLInputElement)
                            ?.files?.[0];
                          if (file) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              uploadFile({
                                file: fileReader.result as string,
                                folder: "giftCategories",
                                upload_preset: "packpendulumconvers",
                              }).then((url) =>
                                setGiftCategoryImageUrl(url as string),
                              );
                            };
                            fileReader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Col>
                  <Form.Label style={{ padding: 5, width: "10%" }}>
                    <b>Klydos:</b>
                  </Form.Label>
                  {draftKlydos && draftKlydos.length > 0 ? (
                    <div>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                        }}
                        className="border-0"
                      >
                        <ListGroup.Item style={{ border: 0, height: "90%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "12px",
                              color: "gray",
                              border: 0,
                            }}
                          >
                            <div style={{ width: 110 }} className="mb-1">
                              IDF
                            </div>
                            <div style={{ width: 110 }} className="mb-1">
                              Author
                            </div>
                            <div
                              style={{
                                width: 35,
                                left: "7px",
                                position: "relative",
                              }}
                              className="mb-1"
                            >
                              GIF
                            </div>
                            <div
                              style={{ width: 25, position: "relative" }}
                              className="mb-1"
                            >
                              Pool
                            </div>
                            <div style={{ width: 6 }} className="mb-1"></div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                          maxHeight: "190px",
                          overflowY: "auto",
                        }}
                        className="border-0"
                      >
                        {draftKlydos!.map((klydo) => (
                          <KlydoListItem
                            canRemove
                            key={klydo.id}
                            klydo={klydo}
                            onRemove={handleRemoveKlydo}
                          />
                        ))}
                      </ListGroup>
                    </div>
                  ) : draftKlydos ? (
                    <p>No klydos in draft</p>
                  ) : (
                    <Spinner />
                  )}
                  <Form
                    style={{ width: "90%", flexDirection: "column" }}
                    className="d-flex justify-content-center"
                  >
                    {draftKlydos && (
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputGroup style={{ width: "53%" }}>
                          <Form.Control
                            style={{
                              zIndex: 0,
                              borderRadius: "0.375rem",
                              borderLeft: "outset",
                              borderRight: "inset",
                              border: "1px solid #969696",
                            }}
                            isValid={klydoValid}
                            placeholder="Add klydo..."
                            required
                            value={inputText}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              let klydo: Klydo | undefined = undefined;
                              let validated = false;
                              firebaseService
                                .findItem<Klydo>(
                                  "klydos",
                                  "name",
                                  inputText,
                                  true,
                                )
                                .then((v) => {
                                  klydo = v;
                                  validated = klydo !== undefined;
                                  setKlydoValid(validated);
                                  if (validated) {
                                    setSelectedKlydo(klydo!);
                                  }
                                });
                              setError(
                                klydoValid || inputText.length === 0
                                  ? { ...error, klydo: "" }
                                  : {
                                      ...error,
                                      klydo: "There is no such klydo",
                                    },
                              );
                              if (klydoValid) {
                                setSuggestions(undefined);
                                setError(
                                  draftKlydos!.includes(selectedKlydo!)
                                    ? {
                                        ...error,
                                        klydo: "klydo already listed",
                                      }
                                    : { ...error, klydo: "" },
                                );
                              } else
                                firebaseService
                                  .getData<Klydo>("klydos")
                                  .then((v) =>
                                    setSuggestions(
                                      v.filter(
                                        (klydo: Klydo) =>
                                          (!draftKlydos.includes(klydo) &&
                                            (klydo.id.includes(inputText) ||
                                              klydo.idf.includes(inputText))) ||
                                          klydo.name.includes(inputText),
                                      ),
                                    ),
                                  );
                            }}
                            type="text"
                          ></Form.Control>

                          {suggestions && (
                            <ul id="suggestionList" className="suggestion-list">
                              {suggestions.map((suggestion) => (
                                <li
                                  className="btn"
                                  style={{
                                    borderRight: 0,
                                    borderRadius: 0,
                                    fontSize: "12px",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    color: "#423e3e",
                                    borderBottom: 0,
                                    borderTop: 0,
                                    zIndex: 999,
                                    border: "1px solid #eee",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    setInputText(suggestion.idf);
                                    setSuggestions(undefined);
                                  }}
                                >
                                  {`name: ${suggestion.name}  /  idf: ${suggestion.idf}`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </InputGroup>
                        {error.klydo && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: 0,
                              marginLeft: "10px",
                            }}
                          >
                            {error.klydo}
                          </p>
                        )}
                        {klydoValid && !error.klydo && (
                          <Button
                            variant="outline-dark"
                            style={{
                              color: "black",
                              height: "fit-content",
                              marginTop: "10px",
                              marginLeft: "5%",
                              marginBottom: "10px",
                              fontSize: "87%",
                            }}
                            onClick={() => {
                              if (
                                selectedKlydo &&
                                !draftKlydos.find(
                                  (k) => k.id === selectedKlydo!.id,
                                )
                              ) {
                                draftKlydos.push(selectedKlydo);
                                setDraftKlydos((prev) => prev!.slice());
                                if (
                                  removedKlydos.find(
                                    (kld) => kld.id === selectedKlydo.id,
                                  )
                                ) {
                                  const tmp = removedKlydos.filter(
                                    (kld) => kld.id !== selectedKlydo.id,
                                  );
                                  setRemovedKlydos(tmp);
                                }
                                setInputText("");
                                setSuggestions(undefined);
                              } else
                                setError({
                                  ...error,
                                  klydo: "klydo already listed",
                                });
                            }}
                          >
                            <IoMdAdd />
                          </Button>
                        )}
                      </div>
                    )}
                  </Form>
                </Col>
                <Form.Group
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "5px",
                  }}
                >
                  {error.server && (
                    <p
                      style={{
                        marginLeft: "102px",
                        marginBottom: "5px",
                        marginTop: "0.25rem",
                        fontSize: "0.875em",
                        color: "#dc3545",
                      }}
                    >
                      {error.server}
                    </p>
                  )}

                  <Button
                    style={{ color: "#4183a8" }}
                    disabled={!name || !giftCategoryImageUrl || !draftKlydos}
                    onClick={() => {
                      setShowSaveModal(true);
                    }}
                  >
                    Save
                  </Button>
                  {showSaveModal && (
                    <Modal
                      onHide={() => setShowSaveModal(false)}
                      className="modal-medium"
                      show={true}
                    >
                      <Modal.Body>
                        <Row style={{ margin: 25 }}>
                          <h4 style={{ textAlign: "center" }}>
                            You are about to save changes for {name}
                          </h4>
                        </Row>
                        <Row className="justify-content-between">
                          <Col sm="5">
                            <Button
                              className="w-100 h-100"
                              onClick={() => setShowSaveModal(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col sm="5">
                            <ButtonLoader
                              variant="outline-danger"
                              className="w-100 h-100"
                              title="Save"
                              onClick={() =>
                                saveGiftCategory().then(() => {
                                  setShowSaveModal(false);
                                  props.setShowModal(false);
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  );
};

export default GiftCategoryModal;
