import { Klydo } from "../Types";
import { baseClient } from "./baseRequest";

export type GiftCategory = {
  _id: string;
  name: string;
  categoryImageUrl: string;
  klydoIds: string[];
};

export type ClockGift = {
  klydo: Klydo;
  from: string;
  image?: string;
  date?: string;
  logo: string;
  letter: string;
};

export type GiftCategoriesClient = {
  getAllCategories: () => Promise<GiftCategory[]>;
  createCategory: (category: Partial<GiftCategory>) => Promise<string>;
  updateCategory: (
    categoryId: string,
    category: Partial<GiftCategory>,
  ) => Promise<void>;
  remoteCategory: (categoryId: string) => Promise<void>;
  deleteClockGift: (clockId: string) => Promise<void>;
  getClockGift: (clockId: string) => Promise<ClockGift | null>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

const giftCategoriesClient: GiftCategoriesClient = {
  deleteClockGift: async (clockId: string) => {
    return baseClient.delete({
      url: "/gift/delete-clock-gift",
      params: {
        ["clock-id"]: clockId,
      },
    });
  },
  getClockGift: async (clockId: string) => {
    return baseClient.get<ClockGift | null>({
      url: "/gift/get-clock-gift",
      params: {
        ["clock-id"]: clockId,
      },
    });
  },
  getAllCategories: async () => {
    return baseClient.get<GiftCategory[]>({
      url: "/gift-categories",
    });
  },
  createCategory: async (category) => {
    return baseClient.post<string>({
      url: "/gift-categories",
      body: category,
    });
  },
  updateCategory: async (categoryId, category) => {
    return baseClient.put({
      url: `/gift-categories/${categoryId}`,
      body: category,
    });
  },
  remoteCategory: async (categoryId) => {
    return baseClient.delete({
      url: `/gift-categories/${categoryId}`,
    });
  },
};

export default giftCategoriesClient;
