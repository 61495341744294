/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard";
import Devices from "./views/Device/components/Devices";
import Versions from "./views/Versions";
import Logs from "./views/Logs";
import Klydos from "./views/Klydos";
import Users from "./views/Users";
import Reviews from "./views/Reviews";
import Tags from "./views/TagsView";
import ScheduledTasks from "./views/ScheduledTasks";
import KlydoMap from "./views/KlydoMap";
import Events from "./views/Events";
import Packs from "./views/Packs";
import PacksReviews from "./views/PacksReviews";
import FeatureFlags from "./views/FeatureFlags";
import GiftCategories from "./views/GiftCategories/components/GiftCategories";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    element: Dashboard,
    layout: "/admin",
  },
  {
    path: "/feature-flags",
    name: "Feature Flags",
    icon: "nc-icon nc-tag-content",
    element: FeatureFlags,
    layout: "/admin",
  },
  {
    path: "/gift-categories",
    name: "Gift Categories",
    icon: "nc-icon nc-bag",
    element: GiftCategories,
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: "nc-icon nc-tablet-2",
    element: Devices,
    layout: "/admin",
  },
  {
    path: "/versions",
    name: "Versions",
    icon: "nc-icon nc-light-3",
    element: Versions,
    layout: "/admin",
  },
  {
    path: "/crashes",
    name: "Crashes",
    icon: "nc-icon nc-paper-2",
    element: Logs,
    layout: "/admin",
  },
  {
    path: "/klydos",
    name: "Klydos",
    icon: "nc-icon nc-palette",
    element: Klydos,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: "nc-icon nc-ruler-pencil",
    element: Reviews,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-single-02",
    element: Users,
    layout: "/admin",
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "nc-icon nc-tag-content",
    element: Tags,
    layout: "/admin",
  },
  {
    path: "/scheduledPool",
    name: "Scheduled Pool",
    icon: "nc-icon nc-puzzle-10",
    element: ScheduledTasks,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: "nc-icon nc-single-copy-04",
    element: Events,
    layout: "/admin",
  },
  {
    path: "/packs",
    name: "Packs",
    element: Packs,
    icon: "nc-icon nc-backpack",
    layout: "/admin",
  },
  {
    path: "/packReviews",
    name: "Packs Reviews",
    element: PacksReviews,
    icon: "nc-icon nc-ruler-pencil",
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Device Map",
    element: KlydoMap,
    icon: "nc-icon nc-map-big",
    layout: "/admin",
  },
];

export default dashboardRoutes;
