import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient, {
  GiftCategory,
} from "../../../api/giftCategories";

const useAddCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["categories"],
    mutationFn: (category: Partial<GiftCategory>) =>
      giftCategoriesClient.createCategory(category),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["categories"],
      }),
  });
};
export default useAddCategoryMutation;
